<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container">
          <div class="grid justify-content-between">
            <div class="col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar
                      size="large"
                      class="primary"
                      icon="pi pi-building"
                    />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      Add New Institution
                    </div>
                    <div class="text-sm text-500">Create new institution</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="md:col-12 comp-grid">
            <div class="">
              <div>
                <form
                  ref="observer"
                  tag="form"
                  @submit.prevent="startSaveRecord()"
                  @reset="resetForm"
                >
                  <div class="grid">
                    <div class="col-12">
                      <div class="mb-2 font-bold text-sm">
                        Institution Name *
                      </div>
                      <div id="ctrl-institution_name-holder">
                        <check-duplicate
                          v-model="formData.institution_name"
                          check-path="components_data/caps_institutions_list_institution_name_exist/"
                          v-slot="checker"
                        >
                          <InputText
                            ref="ctrlinstitution_name"
                            @blur="checker.check"
                            :loading="checker.loading"
                            v-model.trim="formData.institution_name"
                            label="Institution Name"
                            type="text"
                            placeholder="Institution Name"
                            class="w-full"
                            pattern="^[A-Za-z-',)( ]+$"
                            :class="getErrorClass('institution_name')"
                          >
                          </InputText>
                          <small
                            v-if="!checker.loading && checker.exist"
                            class="p-error"
                          >
                            Institution Name already exists</small
                          >
                          <small v-if="checker.loading" class="text-500"
                            >Checking...</small
                          >
                        </check-duplicate>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">
                        Affiliated Institution
                      </div>
                      <div id="ctrl-affiliated_institution-holder">
                        <api-data-source
                          @loaded="
                            (response) =>
                              mapOptionField(response, 'affiliated_institution')
                          "
                          api-path="components_data/affiliated_institution_option_list"
                        >
                          <template v-slot="req">
                            <Dropdown
                              class="w-full"
                              :class="getErrorClass('affiliated_institution')"
                              :loading="req.loading"
                              optionLabel="label"
                              optionValue="value"
                              ref="ctrlaffiliated_institution"
                              v-model="formData.affiliated_institution"
                              :options="req.response"
                              label="Select ..."
                              placeholder="Select ..."
                              :filter="true"
                            >
                            </Dropdown>
                          </template>
                        </api-data-source>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Telephone *</div>
                      <div id="ctrl-telephone-holder">
                        <InputText
                          ref="ctrltelephone"
                          v-model.trim="formData.telephone"
                          label="Telephone"
                          type="text"
                          class="w-full"
                          placeholder="Eg. 233209013836"
                          pattern="^[0-9]+$"
                          maxlength="12"
                          minlength="10"
                          :class="getErrorClass('telephone')"
                        >
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">
                        Institutional Email *
                      </div>
                      <div id="ctrl-institutional_email-holder">
                        <InputText
                          ref="ctrlinstitutional_email"
                          v-model.trim="formData.institutional_email"
                          label="Email"
                          type="email"
                          placeholder="Enter Email"
                          class="w-full"
                          maxLength="40"
                          :class="getErrorClass('institutional_email')"
                        >
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Location *</div>
                      <div id="ctrl-location-holder">
                        <InputText
                          ref="ctrllocation"
                          v-model.trim="formData.location"
                          label="Location"
                          type="text"
                          placeholder="Location"
                          class="w-full"
                          pattern="^[A-Za-z-', ]+$"
                          :class="getErrorClass('location')"
                        >
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Digital Address</div>
                      <div id="ctrl-digital_address-holder">
                        <InputText
                          ref="ctrldigital_address"
                          v-model.trim="formData.digital_address"
                          label="Digital Address"
                          type="text"
                          placeholder="Digital Address"
                          pattern="^[a-zA-Z]{2}[0-9-]+$"
                          class="w-full"
                          maxlength="14"
                          :class="getErrorClass('digital_address')"
                        >
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Website Url *</div>
                      <div id="ctrl-website_url-holder">
                        <InputText
                          ref="ctrlwebsite_url"
                          v-model.trim="formData.website_url"
                          label="Website Url"
                          type="text"
                          placeholder="Eg. https://www.institution.com"
                          class="w-full"
                          pattern="^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$"
                          :class="getErrorClass('website_url')"
                        >
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">
                        Year Of Establishment
                      </div>
                      <div id="ctrl-year_of_establishment-holder">
                        <InputText
                          ref="ctrlyear_of_establishment"
                          v-model.trim="formData.year_of_establishment"
                          label="Year Of Establishment"
                          type="text"
                          placeholder="Eg. 1990"
                          class="w-full"
                          pattern="^[0-9]+$"
                          maxlength="4"
                          minlength="4"
                          :class="getErrorClass('year_of_establishment')"
                        >
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">
                        Start Of Accreditation
                      </div>
                      <div id="ctrl-start_of_accreditation-holder">
                        <Calendar
                          :showButtonBar="true"
                          class="w-full"
                          :class="getErrorClass('start_of_accreditation')"
                          dateFormat="yy-mm-dd"
                          v-model="formData.start_of_accreditation"
                          :showIcon="true"
                          mask="DD/MM/YYYY"
                          outlined
                          dense
                          @dateSelect="validateAccreditationYear"
                        />
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">
                        End Of Accreditation
                      </div>
                      <div id="ctrl-end_of_accreditation-holder">
                        <Calendar
                          :showButtonBar="true"
                          class="w-full"
                          :class="getErrorClass('end_of_accreditation')"
                          dateFormat="yy-mm-dd"
                          v-model="formData.end_of_accreditation"
                          :showIcon="true"
                          mask="DD/MM/YYYY"
                          outlined
                          dense
                          @dateSelect="validateAccreditationYear"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="mb-2 font-bold text-sm">Logo</div>
                      <div id="ctrl-logo-holder">
                        <div class="mb-3">
                          <Uploader
                            :class="getErrorClass('logo')"
                            upload-path="s3uploader/upload/logo"
                            v-model="formData.logo"
                            :fileLimit="1"
                            :maxFileSize="3000000"
                            accept=".jpg,.png,.gif,.jpeg"
                            :multiple="false"
                            label="Choose file or drop file here"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="mb-4 font-bold text-sm">
                        Is this an accredited institution? *
                      </div>
                      <div id="ctrl-is_accredited-holder">
                        <div
                          class="field-radiobutton"
                          v-for="option of $menus.yes_or_noItems"
                          :key="option.value"
                        >
                          <RadioButton
                            :class="getErrorClass('is_accredited')"
                            :id="option.value"
                            name="ctrlis_accredited"
                            :value="option.value"
                            v-model="formData.is_accredited"
                          />
                          <label :for="option.value">{{ option.label }} </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Registrar's Name</div>
                      <div id="ctrl-registrar_name-holder">
                        <InputText
                          ref="ctrlregistrar_name"
                          v-model.trim="formData.registrar_name"
                          label="Registrar's Name"
                          type="text"
                          placeholder="Registrar's Name"
                          class="w-full"
                          pattern="^[A-Za-z-', ]+$"
                          :class="getErrorClass('registrar_name')"
                        >
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">
                        Registrar's Telephone
                      </div>
                      <div id="ctrl-registrar_phone_number-holder">
                        <InputText
                          ref="ctrlregistrar_phone_number"
                          v-model.trim="formData.registrar_phone_number"
                          label="Registrar's Telephone"
                          type="text"
                          class="w-full"
                          placeholder="Eg. 233209013836"
                          pattern="^[0-9]+$"
                          maxlength="10"
                          minlength="10"
                          :class="getErrorClass('registrar_phone_number')"
                        >
                        </InputText>
                      </div>
                    </div>
                  </div>
                  <div v-if="showSubmitButton" class="text-center my-3">
                    <Button
                      class="p-button-primary"
                      type="submit"
                      label="Submit"
                      icon="pi pi-send"
                      :loading="saving"
                    />
                  </div>
                </form>
                <slot :submit="submit" :saving="saving"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
//[--PAGE-IMPORT-STATEMENT--]
import useVuelidate from "@vuelidate/core";
import {
  decimal,
  email,
  integer,
  ipAddress,
  maxLength,
  maxValue,
  minLength,
  minValue,
  numeric,
  required,
  sameAs,
} from "@vuelidate/validators";
import { mapActions, mapGetters, mapState } from "vuex";
import { AddPageMixin } from "../../mixins/addpage.js";
import { PageMixin } from "../../mixins/page.js";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "addCapsinstitutionslistPage",
  components: {
    //[--PAGE-COMPONENT-NAME--]
  },
  mixins: [PageMixin, AddPageMixin],
  props: {
    pageName: {
      type: String,
      default: "caps_institutions_list",
    },
    routeName: {
      type: String,
      default: "caps_institutions_listadd",
    },
    apiPath: {
      type: String,
      default: "caps_institutions_list/add",
    },
  },
  data() {
    return {
      formData: {
        institution_name: "",
        telephone: "",
        location: "",
        digital_address: "",
        website_url: "",
        year_of_establishment: "",
        start_of_accreditation: "",
        end_of_accreditation: "",
        affiliated_institution: "",
        logo: "",
        registrar_name: "",
        registrar_phone_number: "",
        is_accredited: "Yes",
        institutional_email: "",
      },
      start_of_accreditationPicker: false,
      end_of_accreditationPicker: false,
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Add New Institution";
      },
    },
  },
  validations() {
    let formData = {
      institution_name: { required },
      telephone: { required },
      location: { required },
      digital_address: {},
      website_url: { required },
      year_of_establishment: {},
      start_of_accreditation: {},
      end_of_accreditation: {},
      affiliated_institution: {},
      logo: {},
      registrar_name: {},
      institutional_email: { required },
      registrar_phone_number: {},
      is_accredited: { required },
    };
    return { formData };
  },
  methods: {
    ...mapActions("caps_institutions_list", ["saveRecord"]),
    async startSaveRecord() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        this.flashMsg("Form is invalid", "", "error");
        return;
      }
      this.saving = true;
      let payload = this.normalizedFormData();
      let url = this.apiUrl;
      let data = { url, payload };
      this.saveRecord(data).then(
        (response) => {
          this.record = response.data ?? {};
          this.id = this.record["caps_institution_id"] ?? null;
          this.saving = false;
          this.resetForm();
          this.submitted = false;
          this.closeDialogs(); // close page dialog that if opened
          this.flashMsg(this.msgAfterSave);
          this.$emit("submitted", this.record);
          if (this.redirect) this.navigateTo(`/caps_institutions_list`);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    validateAccreditationYear() {
      if (
        this.formData.start_of_accreditation &&
        this.formData.end_of_accreditation
      ) {
        if (
          Date.parse(this.formData.end_of_accreditation) <=
          Date.parse(this.formData.start_of_accreditation)
        ) {
          this.flashMsg(
            "End of accreditation date should be greater than Start date",
            "",
            "error"
          );
          this.formData.end_of_accreditation = "";
        }
      }
    },
    resetForm() {
      this.formData = {
        institution_name: "",
        telephone: "",
        location: "",
        digital_address: "",
        website_url: "",
        year_of_establishment: "",
        start_of_accreditation: "",
        end_of_accreditation: "",
        affiliated_institution: "",
        logo: "",
        registrar_name: "",
        institutional_email: "",
        registrar_phone_number: "",
        is_accredited: "Yes",
      };
      this.updateFormData();
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      return {
        "p-invalid": this.v$.formData[field].$invalid && this.submitted,
      };
    },
  },
  watch: {},
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
<style scoped></style>
